import { authConfig } from "reduxStore/rtk/Query/Config";
import { api } from "reduxStore/Service";

// authenticaton api and package api
const productApi = api.injectEndpoints({
  tagTypes: ["user"],
  endpoints: (builder) => ({

    getUser: builder.query({
      query: (payload) => {
        let url = payload?.url;
        // setting params based on filters available in payload.
        if (payload?.name || payload?.isActive || payload?.sortBy?.name || payload?.sortBy?.email || payload?.page) {
          url = `${payload?.url
            }${payload?.limit ?
              `?limit=${payload?.limit}` : ""
            }${payload?.name &&
            `&search=${payload?.name}`
            }${payload?.isActive && `&isActive=${payload?.isActive}`
            }${payload?.sortBy?.name && `&sortBy=name:${payload?.sortBy.name}`
            }${payload?.sortBy?.email && `&sortBy=email:${payload?.sortBy.email}`
            }${payload?.page && `&page=${payload?.page}`}`;
          return authConfig("GET", url);
        }

        return authConfig("GET", payload?.url);
      },
      transformResponse: (response) => {
        return response;
      },
      providesTags: ["user"],
    }),
    getUserById: builder.mutation({
      query: (payload) => {
        return authConfig("GET", `${payload?.url}`,);
      },
      transformResponse: (response) => {
        return response;
      },
      providesTags: ["user"],
    }),
    creatUser: builder.mutation({
      query: (payload) => {
        return authConfig("POST", `${payload?.url}`, payload?.data);
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["user"],
    }),
    updateUser: builder.mutation({
      query: (payload) => {
        return authConfig("PUT", `${payload?.url}`, payload?.data);
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["user"],
    }),
    deleteUser: builder.mutation({
      query: (payload) => {
        return authConfig("DELETE", `${payload?.url}`);
      },
      invalidatesTags: ["user"],
    }),
    setStatus: builder.mutation({
      query: (payload) => {
        return authConfig("PUT", `${payload?.url}`, payload?.data);
      },
      invalidatesTags: ["user"],
    }),
  }),
});
export const {
  useLazyGetUserQuery,
  useGetUserByIdMutation,
  useCreatUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useSetStatusMutation,
} = productApi;
