import { InfoWindowF } from "@react-google-maps/api";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { firstLetterUpperCase } from "modules/helper";
import { Card } from "react-bootstrap";
export const MarkerComponent = ({ setMarker, marker }) => {
  return (
    <InfoWindowF
      position={{
        lat: marker?.latitude,
        lng: marker?.longitude,
      }}
      headerDisabled={true}
      options={{
        ariaLabel: "CustomerLeadInfo",
        pixelOffset: new window.google.maps.Size(0, -38),
      }}
      onCloseClick={() => setMarker(null)}
    >
      <div  className="customer-lead-info-window">
        <Card className="card-body mb-0 position-relative ">
          <div className="d-flex mb-4 align-items-center ">
            <div className="flex-shrink-0">
              <i
                className="bi bi-person-circle"
                style={{
                  color: "#00529D",
                  fontSize: "xx-large",
                }}
              ></i>
            </div>
            <div className="flex-grow-1 ms-2">
              <h5 className="card-title mb-1">
                {marker?.name}{" "}
                <Tooltips
                  msg={marker?.customerId ? "Customer Id" : "Lead No"}
                  direction={"right"}
                >
                  <span
                    className="badge-label badge bg-success"
                    style={{ fontSize: "x-small" }}
                  >
                    {marker?.customerId ?? marker?.leadId}
                  </span>
                </Tooltips>
              </h5>
              <Tooltips msg={"Job Id"} direction={"left"}>
                <p className="text-muted mb-0">{marker?.jobid}</p>
              </Tooltips>
            </div>
            <span
              className=" text-muted float-right position-absolute"
              onClick={() => {
                setMarker(null);
              }}
              style={{
                top: "14px",
                cursor: "pointer",
                right: " 0px",
                color: "gray",
              }}
            >
              <i
                className=" ri-close-circle-line"
                style={{ fontSize: "26px" }}
              />
            </span>
          </div>
          <Tooltips msg={"Location"} direction={"left"}>
            <h6 className="mb-2">
              <i
                className=" ri-user-location-line"
                style={{ color: "#00529D" }}
              ></i>
              &nbsp;
              {firstLetterUpperCase(marker?.address)},
            </h6>
          </Tooltips>
          <Tooltips msg={"Location"} direction={"left"}>
            <h6 style={{ marginLeft: "20px" }}>
              {firstLetterUpperCase(marker?.city)},&nbsp;
              {marker?.state.toUpperCase()},&nbsp;
              {marker?.zipcode}
            </h6>
          </Tooltips>
          <Tooltips msg={"Logged Date"} direction={"left"}>
            <h6 className="mt-2 mb-0">
              <i
                className="bi bi-calendar-date"
                style={{ color: "#00529D" }}
              ></i>
              &nbsp;
              {marker.loggedDate}
            </h6>
          </Tooltips>
          <hr />
          <p className="card-text text-muted m-0 ">
            <i className="bx bx-user-check " style={{ color: "#00529D" }}></i>
            &nbsp; Assigned To
          </p>
          <h6 className=" mb-2">
            {marker?.customerId
              ? marker.employee1
                ? marker.employee1
                : marker.employee2
                  ? marker.employee2
                  : marker.employee3
              : marker?.assignedTo}
          </h6>
        </Card>
      </div>
    </InfoWindowF>
  );
  // )}
  // </MarkerF>
};
