import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isFullScreenMode, setIsFullScreenMode] = useState(true);
  return (
    <AuthContext.Provider
      value={{ user, setUser, isFullScreenMode, setIsFullScreenMode }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
