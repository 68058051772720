import { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteModal } from "components/common/DeleteModal";
import { ReactTable } from "../tables/ReactTable";
import { showErrorMsg, showSuccessMsg, timeFormat } from "modules/helper";
import { convertToTime, DateFormat } from "./CollumnFormator";
import NotificationForm from "./Form";
import {
  useDeleteNotificationMutation,
  useLazyGetNotificationQuery,
  usePauseNotificationMutation,
} from "reduxStore/rtk/EndPoints/NotificationEndPoint/NotificationSlice";
import moment from "moment";
import { PauseAndResumeModal } from "components/common/NofificationConformationModal";
import { Dropdown } from "react-bootstrap";
import { getActionButtonsDropdown } from "modules/util";

export const NotificationList = ({
  notificationFilter,
  setNotificationFilter,
}) => {
  const [getNotification, { data, isSuccess }] = useLazyGetNotificationQuery();
  useEffect(() => {
    getNotification(notificationFilter);
    // eslint-disable-next-line
  }, [notificationFilter]);
  const [deleteNotification] = useDeleteNotificationMutation(); // rtk function to call delete api
  const [pauseNotification] = usePauseNotificationMutation(); //rtk function to call pause notification api
  const [deletid, setDeletid] = useState(); // stores notification id to delete
  const [removeId, setremoveId] = useState(false); // show/hide delete modal
  const [resumeid, setResumeid] = useState(); // stores notification id to pause/resume
  const [pauseId, setPauseId] = useState(false); // show/hide notification pause resume modal
  const [modalData, setModalData] = useState(null); // stores notification data , passed as prop , will be used to get information about notification
  const [editNotification, setEditNotification] = useState(false); // show/hide update notification modal
  const [editId, setEditId] = useState(""); // stores notification id which is going to be updated.

  /** Toggle NotificationForm(to update notification)
   * Storing the information of notification which we want to update in state variable(editUserDetails) to pass as prop in userModal component
   * NotificationForm component will use this information while calling update notification api.
   */
  const handleNotiUpdate = (value) => {
    if (value) {
      setEditId(value);
      setEditNotification(!editNotification);
    }
  };

  /**
   * Handling delete notification modal
   * removeId  state variable to show/hide delete notification modal
   * Delete id stores the notification id which we want to delete
   * passing delete id as prop in delete notification modal which will be used to call delete api
   */
  const handleRemoveModal = useCallback(
    (data) => {
      if (removeId === true) {
        setDeletid("");
      } else {
        setDeletid(data);
      }
      setremoveId(!removeId);
    },
    [removeId]
  );
  /**
   * Pause and resume notification
   * storing notification id in resumeid state variable
   * pause id is used to show/hide conformation modal
   */
  const handlePauseResumeModal = useCallback(
    (data) => {
      if (pauseId === true) {
        setResumeid("");
      } else {
        setResumeid(data);
      }
      setPauseId(!pauseId);
    },
    [pauseId]
  );

  /*
    function handle delete notification api
    close modal after calling api
   */
  const handleRemoveId = () => {
    if (deletid) {
      deleteNotification({ url: `/notifications/${deletid}` }).then((res) => {
        if (res?.data?.type === "success") {
          showSuccessMsg(res?.data?.message);
          // getNotification();
        } else {
          showErrorMsg(res?.data?.message);
        }
        setremoveId(false);
      });
    }
  };
  /**
   *  handle pause or resume notification
   * show toast after getting response
   * close modal after getting response
   */
  const handleResumeId = () => {
    if (resumeid) {
      pauseNotification(resumeid).then((res) => {
        if (res?.data?.type === "success") {
          showSuccessMsg(res?.data?.message);
        } else {
          showErrorMsg(res?.error?.data?.message);
        }
        setPauseId(false);
      });
    }
  };

  // contains columns which will be displayed in notification table.
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body d-flex align-items-center   mx-2 ">
                {cell.row.original.name}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Hail Size",
        accessor: "hailSize",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle  ">
                <span className="badge bg-light text-uppercase text-dark">
                  <i className="ri-thunderstorms-fill mx-1 text-info"></i>
                  {cell.row.original.hailSize}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Creator",
        accessor: "userId?.name",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle  ">
                {cell.row.original.userId?.name ? (
                  <span className="badge bg-success-subtle text-uppercase text-success">
                    {cell.row.original.userId?.name}
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </div>
          );
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "isPaused",
      //   Filter: false,
      //   isSortable: false,
      //   Cell: (cell) => {
      //     return (
      //       <div className="d-flex gap-1">
      //         <div className="text-body align-middle  ">
      //           {cell.row.original.isPaused ? (
      //             <span className="badge bg-danger-subtle text-uppercase text-danger">
      //               Pause
      //             </span>
      //           ) : (
      //             <span className="badge bg-success-subtle text-uppercase text-success">
      //               Play
      //             </span>
      //           )}
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Time",
        accessor: "time",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle  ">
                <DateFormat value={convertToTime(cell.row.original?.time)} />
              </div>
            </div>
          );
        },
      },
      {
        Header: "Created On",
        accessor: "createdAt",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle  ">
                {timeFormat(cell.row.original.createdAt)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell) => (
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              className="btn btn-soft-info btn-sm arrow-none"
            >
              <i className="las la-ellipsis-h align-middle fs-18"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-end">
              <li>
                {/*getActionButtonsDropdown
                 * This function return dropdown items
                 */}
                {getActionButtonsDropdown(
                  "Delete", // button name
                  "Delete Notification", // tooltip name
                  () => handleRemoveModal(cell.row.original?._id), // on click action
                  "ri-close-circle-fill", // icon class
                  "danger" // button variant
                )}
              </li>
              <li>
                {getActionButtonsDropdown(
                  "Update",
                  "Update Notification",
                  () => handleNotiUpdate(cell.row.original?._id),
                  "ri-edit-2-fill",
                  "primary"
                )}
              </li>
              <li>
                {getActionButtonsDropdown(
                  cell.row.original?.isPaused ? "Resume" : "Pause",
                  cell.row.original?.isPaused
                    ? "Resume Notification"
                    : "Pause Notification",
                  () => {
                    setModalData(cell.row.original?.isPaused);
                    handlePauseResumeModal(cell.row.original?._id);
                  },
                  cell.row.original?.isPaused
                    ? "ri-play-line"
                    : "ri-pause-line",
                  cell.row.original?.isPaused ? "info" : "warning"
                )}
              </li>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <ReactTable
        data={data?.data?.results} // data-> notification data
        isSuccess={isSuccess} // manage loading
        columns={columns}
        pageInfo={{
          totalPages: data?.data?.totalPages,
          currentPage: data?.data?.page,
          totalCount: data?.data?.totalResults,
          limit:
            data?.data?.limit > data?.data?.totalResults
              ? data?.data?.totalResults
              : data?.data?.limit,
        }}
        filter={notificationFilter}
        setFilter={setNotificationFilter}
      />
      <DeleteModal
        title={{ text: "Delete Notification", btnText: "Delete" }}
        show={removeId}
        handleClose={handleRemoveModal}
        deleteModalFunction={handleRemoveId}
        text="notification"
      />
      <PauseAndResumeModal
        title={{ text: "notification", btnText: "Delete" }}
        show={pauseId}
        data={modalData}
        handleClose={handlePauseResumeModal}
        deleteModalFunction={handleResumeId}
        text="notification"
      />
      <NotificationForm
        modalTitle="Update Notification"
        isShow={editNotification}
        handleClose={() => setEditNotification(false)}
        id={editId}
        setEditId={setEditId}
      />
    </>
  );
};
