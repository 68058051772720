import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useDeleteUserMutation,
  useLazyGetUserQuery,
} from "reduxStore/rtk/EndPoints/UserEndPoint/UserSlice";
import { DeleteModal } from "components/common/DeleteModal";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { ReactTable } from "../tables/ReactTable";
import { showErrorMsg, showSuccessMsg, timeFormat } from "modules/helper";
import { Dropdown, Form, Image } from "react-bootstrap";
import dummy from "assets/images/users/user-dummy-img.jpg";
import UserModal from "components/common/UserModal";
import UserStatusModal from "./UserStatusModal";
import moment from "moment";
import UserChangePassword from "./UserChangePassword";
import { getActionButtonsDropdown } from "modules/util";

export const UserList = ({ userFilter, setUserFilter }) => {
  const [getUser, { data, isSuccess }] = useLazyGetUserQuery(userFilter);
  const [deleteUser] = useDeleteUserMutation();
  const [deleteId, setDeleteId] = useState(); // store the user id to be deleted
  const [removeId, setremoveId] = useState(false); // used  to show/hide Delete Modal
  const [editUser, setEditUser] = useState(false); // show/hide Edit user Modal
  const [id, setId] = useState("");
  const [editUserDetails, setEditUserDetails] = useState({});
  const [showStatusModal, setShowStatusModal] = useState(false); // show/hide change status conformation modal
  const [userInfoForStatusToggle, setUserInfoForStatusToggle] = useState("");

  /**Toggle user status.
   * storing the details of user on which we clicked to change status, in state variable(userInfoForStatusToggle).
   * passing the state(userInfoForStatusToggle) as a prop in status Modal, to get the user Id , user current active status in Modal.
   * user details will be used while calling api.
   */
  const handleUserStatus = (currentUser) => {
    setUserInfoForStatusToggle(currentUser);
    setShowStatusModal(true);
  };

  /** Toggle UserModal(to update user)
   * Storing the information of user which we want to update in state variable(editUserDetails) to pass as prop in userModal component
   * UserModal component will use this information while calling update user api.
   */
  const handleUserUpdate = (userData) => {
    if (userData) {
      setEditUserDetails(userData);
      setEditUser(!editUser);
    }
  };

  /** setId state variable will be used to show/hide change password modal
   * storing user id in setId state variable and passing it as a prop in UserChangePassword component
   */
  const handleChangePassword = (value) => {
    if (value) {
      setId(value);
    }
  };

  /**
   * Handling delete user modal
   * removeId  state variable to show/hide delete user modal
   * Delete id stores the user id which we want to delete
   * passing delete id as prop in delete user modal which will be used to call delete api
   */
  const handleRemoveModal = useCallback(
    (userId) => {
      if (removeId) {
        setDeleteId("");
      } else {
        setDeleteId(userId);
      }
      setremoveId(!removeId);
    },
    [removeId]
  );

  /**function used to handle delete user api
   * this function is passed as prop in delete modal , and invoked from there
   */
  const handleRemoveId = () => {
    if (deleteId) {
      deleteUser({ url: `/users/${deleteId}` }).then((res) => {
        if (res?.data?.type === "success") {
          showSuccessMsg(res?.data?.message);
          getUser(userFilter);
        } else {
          showErrorMsg(res?.data?.message);
        }
        setremoveId(false);
      });
    }
  };

  // used to create columns for user list table
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body d-flex align-items-center  mx-2 ">
                <div className="position-relative mx-2 py-1">
                  <Image
                    src={
                      cell.row.original.profileImage
                        ? process.env.REACT_APP_IMAGE_BASE_URL +
                          "profile/" +
                          cell.row.original.profileImage
                        : " "
                    }
                    alt=""
                    className="avatar-xs rounded-circle border border-2    "
                    // setting fallback image if src image doesn't load
                    onError={(event) => {
                      event.currentTarget.src = dummy;
                      event.currentTarget.onerror = null;
                    }}
                  />
                </div>
                {cell.row.original.name}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Filter: false,
        isSortable: true,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle ">
                {cell.row.original.email}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "isActive",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <Tooltips
                msg={`Click To ${
                  cell.row.original.isActive ? "Deactivate" : "Activate"
                }`}
                direction={"right"}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="text-body align-middle  "
                  onClick={() => handleUserStatus(cell.row.original)}
                >
                  {cell.row.original.isActive ? (
                    <div
                      className="form-check form-switch form-switch-lg "
                      dir="ltr"
                    >
                      <Form.Check
                        id="customSwitchsizelg"
                        checked={true}
                        onChange={() => {}}
                      />
                    </div>
                  ) : (
                    <div
                      className="form-check form-switch form-switch-lg"
                      dir="ltr"
                    >
                      <Form.Check
                        id="customSwitchsizelg"
                        checked={false}
                        onChange={() => {}}
                      />
                    </div>
                  )}
                </div>
              </Tooltips>
            </div>
          );
        },
      },
      {
        Header: "Created On",
        accessor: "createdAt",
        Filter: false,
        isSortable: false,
        Cell: (cell) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body align-middle">
                {timeFormat(cell.row.original.createdAt)}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        isSortable: false,
        Cell: (cell) => (
          <Dropdown>
            <Dropdown.Toggle
              as="button"
              className="btn btn-soft-info btn-sm arrow-none"
            >
              <i className="las la-ellipsis-h align-middle fs-18"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-end">
              <li>
                {/*getActionButtonsDropdown
                 * This function return dropdown items
                 */}
                {getActionButtonsDropdown(
                  "Delete", //button name
                  "Delete User", // tooltip name
                  () => handleRemoveModal(cell.row.original?._id), // onClick action
                  "ri-close-circle-fill", // icons used
                  "danger" // color of the button
                )}
              </li>
              <li>
                {getActionButtonsDropdown(
                  "Update",
                  "Update User",
                  () => handleUserUpdate(cell.row.original),
                  "ri-edit-2-fill",
                  "primary"
                )}
              </li>
              <li>
                {getActionButtonsDropdown(
                  "Change Password",
                  "Change Password",
                  () => handleChangePassword(cell.row.original?._id),
                  "ri-lock-2-fill",
                  "info"
                )}
              </li>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  useEffect(() => {
    if (localStorage.getItem("USER_ROLE") === "Admin") {
      getUser(userFilter);
    }
    // eslint-disable-next-line
  }, [userFilter]);
  return (
    <>
      <ReactTable // render table based on prop
        data={data?.results} // data represents user data
        isSuccess={isSuccess} // manage loading
        columns={columns}
        pageInfo={{
          totalPages: data?.totalPages,
          currentPage: data?.page,
          totalCount: data?.totalResults,
          limit:
            data?.limit > data?.totalResults ? data?.totalResults : data?.limit,
        }}
        filter={userFilter}
        setFilter={setUserFilter}
      />
      <DeleteModal
        title={{ text: "Delete User", btnText: "Delete" }}
        show={removeId}
        handleClose={handleRemoveModal}
        deleteModalFunction={handleRemoveId}
        text="user"
      />
      <UserModal // modal to update user
        modalTitle="Update User"
        isShow={editUser}
        handleClose={() => setEditUser(false)}
        edit={editUserDetails}
      />
      <UserStatusModal
        show={showStatusModal}
        handleClose={() => setShowStatusModal(false)}
        user={userInfoForStatusToggle}
      />
      <UserChangePassword
        modalTitle="Change User Password"
        isShow={id ? true : false}
        handleClose={() => setId(false)}
        id={id}
        setId={setId}
      />
    </>
  );
};
