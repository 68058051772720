import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import TableContainer from "components/common/Tabledata/TableContainer";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { convertToExcel, showWarningMsg } from "modules/helper";
import Slider from "rc-slider";
import React, { useMemo, useRef, useState } from "react";
import { Modal, Button, Dropdown, Card, Spinner } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import { useGetNearbySalesmanMutation } from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";

const libraries = ["places", "drawing", "geometry"];
export const SalesFilterModal = ({
  show,
  handleClose = () => {},
  setSalesFilter,
  mapRef,
  salesFilter,
  searchedLocation,
  setSearchedLocation,
}) => {
  const [range, setRange] = useState(0);
  const [errors, setErrors] = useState({
    // state to show error messages
    address: {
      error: false,
      message: "Please Select Valid Location",
    },
    miles: {
      error: false,
      message: "Please Select Miles",
    },
  });
  const searchRef = useRef();
  const [getNearbySalesman, { isLoading: isSalesDataLoading }] =
    useGetNearbySalesmanMutation(); // get data of nearby sales man

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const [salesData, setSalesData] = useState(null);
  /* call api and get nearby sales man data */
  const handleApplySalesFilter = async () => {
    try {
      if (salesFilter.lat === "" || salesFilter.lng === "") {
        showWarningMsg(errors.address.message);
        setErrors({ ...errors, address: { ...errors.address, error: true } });
        return;
      }
      if (salesFilter.miles === "" || salesFilter?.miles === 0) {
        showWarningMsg(errors.miles.message);
        setErrors({ ...errors, miles: { ...errors.miles, error: true } });
        return;
      }
      let res = await getNearbySalesman({
        url: "sage/nearby-sales-person",
        data: {
          latitude: salesFilter.lat,
          longitude: salesFilter.lng,
          miles: salesFilter.miles,
        },
      });
      setSalesData(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  // function to download nearby sales person excel data
  const downloadNearbySalesPersonData = async () => {
    let res = await getNearbySalesman({
      url: "sage/nearby-sales-person",
      data: {
        latitude: salesFilter.lat,
        longitude: salesFilter.lng,
        miles: salesFilter.miles,
      },
      export: true,
    });
    convertToExcel(res?.data?.data);
  };
  const onLoadAutocomplete = (autocomplete) => {
    searchRef.current = autocomplete;
  };

  const onPlaceChanged = async () => {
    try {
      const place = searchRef.current.getPlace();
      const bounds = new window.google.maps.LatLngBounds();

      if (place.geometry?.viewport) {
        bounds.union(place.geometry?.viewport);
      } else {
        bounds.extend(place.geometry?.location);
      }
      if (place?.geometry) {
        setSalesFilter((prev) => ({
          ...prev,
          lat: place?.geometry?.location?.lat(),
          "location Name": place?.name,
          lng: place?.geometry?.location?.lng(),
        }));
      }
      mapRef?.current?.fitBounds(bounds);
    } catch (error) {
      clearInputs()
      showWarningMsg("Please Select Valid Location");
    }
  };

  const clearInputs = () => {
    setSalesFilter((prev) => ({
      ...prev,
      lat: "",
      lng: "",
      "location Name": "",
    }));
    setSalesData(null)
  }
  const autocompleteStyle = {
    boxSizing: "border-box",
    border: "1px solid black",
    width: "100%",
    height: "38px",
    padding: "0 12px",
    borderRadius: "3px",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipses",
  };

  const columns = useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "name",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "ADDRESS",
        accessor: "address",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "DISTANCE",
        accessor: "distance",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "EMPLOYEE",
        accessor: (salesData) => {
          if (salesData.employee1) return salesData.employee1;
          if (salesData.employee2) return salesData.employee2;
          if (salesData.employee3) return salesData.employee3;
        },
        Filter: false,
        isSortable: true,
      },
    ],
    [],
  );

  const closeNearbySalesModal = () => {
    handleClose();
    setSalesFilter({
      // clear sales filter after closing
      lat: "",
      lng: "",
      miles: "",
    });
    setRange(0);
    setSalesData(null);
  };
  const sliderStyle = {
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    width: "250px",
  };
  return (
    <React.Fragment>
      {isLoaded ? (
        <Modal // search sales person modal
          size="xl"
          show={show}
          onHide={() => closeNearbySalesModal()}
          backdrop={"static"}
          keyboard={false}
          id="staticBackdrop"
          centered
        >
          <Modal.Header
            className="modal-title fw-bold"
            id="staticBackdropLabel"
            closeButton
          >
            Find Nearby Sales Person
          </Modal.Header>
          <Modal.Body className="text-center px-5">
            <div className="d-flex align-items-end ">
              <div className="text-start w-100 mx-1">
                <div>Address</div>
                <Autocomplete // for searching location on map
                  onLoad={onLoadAutocomplete}
                  onPlaceChanged={onPlaceChanged}
                  restrictions={{
                    country: "us", // search restricted to country
                  }}
                  options={{
                    fields: [
                      "address_components",
                      "formatted_address",
                      "geometry",
                      "name",
                      "icon",
                    ],
                    strictBounds: true,
                  }}
                  className=""
                >
                  <input
                    type="search"
                    placeholder="Search Location"
                    style={autocompleteStyle}
                    onChange={() => clearInputs()}
                  />
                </Autocomplete>
              </div>

              <div className="text-start">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline"
                    className=" btn btn-subtle-primary"
                    style={{ minWidth: "125px" }}
                  >
                    <Tooltips msg={"Select Range"}>
                      <span>{range} Miles</span>
                    </Tooltips>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="my-1 range-dropdown"
                    style={sliderStyle}
                  >
                    {/* <Dropdown.Item > */}
                    <div className="m-2 py-2  ">
                      <div className="d-flex my-2 justify-content-between">
                        <div>Miles </div>
                        <div>{range}</div>
                      </div>
                      <Slider
                        min={0}
                        max={10}
                        value={range}
                        step={0.25}
                        className="text-primary"
                        onChange={(value) => setRange(value)}
                        onChangeComplete={(nextValue) => {
                          setSalesFilter((prev) => ({
                            ...prev,
                            miles: nextValue,
                          }));
                          setSalesData(null);
                        }}
                        styles={{
                          track: {
                            background: `#00529D`,
                          },
                        }}
                      />
                    </div>
                    {/* </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {isSalesDataLoading && (
              <div
                style={{ zIndex: 1 }}
                className=" position-fixed top-50 start-50 translate-middle"
              >
                <PuffLoader
                  color={"black"}
                  loading={true}
                  size={176}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            {salesData && (
              <Card>
                <Card.Body>
                  {/* show sales data in table */}
                  <TableContainer
                    columns={columns}
                    data={salesData?.data || []}
                    customPageSize={9}
                    divClassName="table-card table-responsive text-start mt-3"
                    tableClass="table-hover table-nowrap align-middle mb-0 border table table-striped "
                    isBordered={false}
                    PaginationClass="align-items-center mt-4 gy-3"
                  />
                </Card.Body>
              </Card>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              {salesData?.data?.length > 0 && (
                <Tooltips msg={"Download Excel"}>
                  <Button
                    disabled={isSalesDataLoading}
                    onClick={downloadNearbySalesPersonData}
                  >
                    {isSalesDataLoading && <Spinner size="sm" />}
                    <i className="bi bi-file-earmark-excel-fill"></i>
                  </Button>
                </Tooltips>
              )}
              <Button
                type="button"
                variant="primary"
                className="btn w-sm btn-hover"
                id="delete-record"
                onClick={() => handleApplySalesFilter()}
                disabled={isSalesDataLoading}
              >
                {isSalesDataLoading && <Spinner size="sm" />} Find
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};
