import { useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import TableContainer from "components/common/Tabledata/TableContainer";
import Tooltips from "components/common/tooltipBox/Tooltip";
import { durationOptions } from "modules/filters";
import { showWarningMsg } from "modules/helper";
import Slider from "rc-slider";
import { Modal, Button, Dropdown, Card, Spinner } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import * as XLSX from "xlsx";
const libraries = ["places", "drawing", "geometry"];
const possibleErrors = {
  address: {
    error: false,
    message: "Please Select Valid Location",
  },
  miles: {
    error: false,
    message: "Please Select Miles",
  },
  range: {
    error: false,
    message: "Please Select Duration",
  },
};

export const WeatherHistory = ({
  show,
  handleClose = () => {},
  setWeatherHistoryFilter,
  weatherHistoryFilter,
  mapRef,
  getWeatherData,
  isLoadingWeatherData,
}) => {
  const [range, setRange] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [weatherData, setWeatherData] = useState(null);
  const [errors, setErrors] = useState(possibleErrors);
  const searchRef = useRef();
  const [filterData, setFilterData] = useState({
    limit: 10,
    page: 1,
    sortBy: {},
  });
  const [tableData, setTableData] = useState([]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleWeatherHistoryFilter = async () => {
    //check for errors before calling api
    try {
      if (weatherHistoryFilter.lat === "" || weatherHistoryFilter.lng === "") {
        showWarningMsg(errors.address.message);
        setErrors((prev) => ({
          ...prev,
          address: { ...prev.address, error: true },
        }));
        return;
      }
      if (
        weatherHistoryFilter.range === "" ||
        weatherHistoryFilter.range?.value === null
      ) {
        showWarningMsg(errors.range.message);
        setErrors((prev) => ({
          ...prev,
          range: { ...prev.range, error: true },
        }));
        return;
      }
      // call api to get weather data
      let res = await getWeatherData({
        url: "weather/history",
        data: {
          filter: {
            range: weatherHistoryFilter.range?.value ?? 0,
            coordinates: [weatherHistoryFilter.lat, weatherHistoryFilter.lng],
            radius: weatherHistoryFilter.miles,
          },
        },
      });
      // formatting dat according to our need
      const formattedData = res.data?.data?.features
        ?.map((e) => ({
          HailSize: e?.properties?.HailSize,
          Date: e?.properties?.Date, // date is stored without conversion, REASON: without converting we can sort dates properly.
        }))
        ?.sort((a, b) => new Date(b.Date) - new Date(a.Date)); // sorting the formatted data in ascending order(by dates)

      // reset previous filters
      setFilterData({
        limit: 10,
        page: 1,
        sortBy: {},
      });

      //weather data and excel data stored separately to prevent mutation while sorting and downloading
      setWeatherData(formattedData);
      setExcelData(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleExport = async () => {
    setDownloadingExcel(true);
    try {
      // Prepare data with formatted dates
      const formattedData = formatDatesInData(excelData);

      // Create a worksheet from the formatted data
      const worksheet = XLSX.utils.json_to_sheet(formattedData, {
        header: Object.keys(formattedData[0]),
        dateNF: "yyyy-mm-dd", // Excel date format
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const fileName = `weather-history-${new Date().getTime()}.xlsx`;
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setDownloadingExcel(false);
    }
  };

  const formatDatesInData = (data) => {
    if (!data.length) return [];
    const headers = Object.keys(data[0]);

    return data.map((row) => {
      return headers.reduce((acc, header) => {
        if (header === "Date" && row[header]) {
          acc[header] = new Date(row[header]).toLocaleDateString(undefined, {
            year: "numeric",
            month: "short",
            day: "2-digit",
          });
        } else {
          acc[header] = row[header];
        }
        return acc;
      }, {});
    });
  };

  const onLoadAutocomplete = (autocomplete) => {
    searchRef.current = autocomplete;
  };

  const onPlaceChanged = async () => {
    //get place coordinates according to location enterd
    try {
      const place = searchRef.current.getPlace();
      const bounds = new window.google.maps.LatLngBounds();
      if (place.geometry?.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
      if (place?.geometry) {
        // store coordinates in state
        setWeatherHistoryFilter((prev) => ({
          ...prev,
          lat: place.geometry.location.lat(),
          "location Name": place.name,
          lng: place.geometry.location.lng(),
        }));
      } else {
      clearAllValue()
      }
      mapRef?.current?.fitBounds(bounds);
    } catch (error) {
      clearAllValue()
      showWarningMsg("Please Select Valid Location");
    }
  };

  const autocompleteStyle = {
    boxSizing: "border-box",
    border: "1px solid black",
    width: "100%",
    height: "38px",
    padding: "0 12px",
    borderRadius: "3px",
    fontSize: "14px",
    outline: "none",
    textOverflow: "ellipsis",
  };

  const columns = useMemo(
    () => [
      {
        Header: "HAILSIZE",
        accessor: "HailSize",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "DATE",
        accessor: "Date",
        Filter: false,
        isSortable: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-1">
              <div className="text-body d-flex align-items-center mx-2">
                <div className="position-relative mx-2 py-1">
                  {new Date(row?.original?.Date)?.toLocaleDateString("en-GB", {
                    timeZone: systemTimeZone,
                    year: "numeric",
                    day: "2-digit",
                    month: "short",
                  })}
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    [systemTimeZone]
  );

  const closeWeatherHistoryModal = () => {
    handleClose();
    clearAllValue();
    setRange(0);
  };
  const clearAllValue = () => {
    setWeatherHistoryFilter({
      lat: "",
      lng: "",
      miles: 0,
      range: "",
    });
    setWeatherData(null);
    setTableData([]);
    setExcelData([]);
  };

  const sliderStyle = {
    padding: "0 12px",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    width: "250px",
  };

  const itemsPerPage = 10;

  const getTotalPages = (data, itemsPerPage) => {
    return Math.ceil((data?.length ?? 0) / itemsPerPage);
  };

  // slice data upto items per-page to show in table
  const getDataForPage = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (weatherData) {
      const sortedData = [...weatherData]; // store weather data in variable to prevent mutation
      if (filterData.sortBy.date) {
        sortedData.sort((a, b) => {
          const dateA = new Date(a.Date);
          const dateB = new Date(b.Date);
          return filterData.sortBy.date === "asc"
            ? dateA - dateB
            : dateB - dateA;
        });
      }
      if (filterData.sortBy.hailSize) {
        sortedData.sort((a, b) => {
          const hailSizeA = Number(a.HailSize);
          const hailSizeB = Number(b.HailSize);
          return filterData.sortBy.hailSize === "asc"
            ? hailSizeA - hailSizeB
            : hailSizeB - hailSizeA;
        });
      }
      // slice data from sorted data to store in table
      const paginatedData = getDataForPage(
        sortedData,
        filterData.page,
        itemsPerPage
      );
      setExcelData(sortedData.length ? sortedData : []); // storing the whole sorted data in excelData state
      setTableData(paginatedData.length ? paginatedData : []); // storing sliced data in tableData to show in table.
    }
  }, [weatherData, filterData]);

  return (
    <>
      {isLoaded ? (
        <Modal
          size="xl"
          show={show}
          onHide={closeWeatherHistoryModal}
          backdrop={"static"}
          keyboard={false}
          id="staticBackdrop"
          centered
        >
          <Modal.Header
            className="modal-title fw-bold"
            id="staticBackdropLabel"
            closeButton
          >
            Weather History
          </Modal.Header>
          <Modal.Body className="text-center px-5">
            <div className="row align-items-end">
              <div className="col-8 text-start">
                <div>Address</div>
                <Autocomplete
                  onLoad={onLoadAutocomplete}
                  onPlaceChanged={onPlaceChanged}
                  restrictions={{ country: "us" }}
                  options={{
                    fields: [
                      "address_components",
                      "formatted_address",
                      "geometry",
                      "name",
                      "icon",
                    ],
                    strictBounds: true,
                  }}
                >
                  <input
                    type="search"
                    placeholder="Search Location"
                    style={autocompleteStyle}
                    onChange={() => {
                      clearAllValue()
                    }}
                  />
                </Autocomplete>
              </div>
              <div className="col-2 text-end">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline"
                    className="btn btn-subtle-primary"
                    style={{ minWidth: "125px" }}
                  >
                    <Tooltips msg={"Select Range"}>
                      <span>{range} Miles</span>
                    </Tooltips>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="my-1 range-dropdown"
                    style={sliderStyle}
                  >
                    <div className="m-2 py-2">
                      <div className="d-flex my-2 justify-content-between">
                        <div>Miles</div>
                        <div>{range}</div>
                      </div>
                      <Slider
                        min={0}
                        max={10}
                        value={range}
                        step={0.25}
                        className="text-primary"
                        onChange={(value) => setRange(value)}
                        onChangeComplete={(nextValue) => {
                          setWeatherHistoryFilter((prev) => ({
                            ...prev,
                            miles: nextValue,
                          }));
                        }}
                        styles={{ track: { background: `#00529D` } }}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-2">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline"
                    className="btn btn-subtle-primary"
                    style={{ minWidth: "125px" }}
                  >
                    <Tooltips msg={"Select Duration"}>
                      <span>
                        {weatherHistoryFilter?.range?.label ||
                          "Select Duration"}{" "}
                      </span>
                    </Tooltips>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {durationOptions?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setWeatherHistoryFilter((prev) => ({
                            ...prev,
                            range: item,
                          }));
                        }}
                      >
                        {item.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {isLoadingWeatherData && (
              <div
                style={{ zIndex: 1 }}
                className="position-fixed top-50 start-50 translate-middle"
              >
                <PuffLoader
                  color={"black"}
                  loading={true}
                  size={176}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            {weatherData !== null && !isLoadingWeatherData && (
              <Card className="m-0 p-0">
                <Card.Body>
                  <TableContainer
                    isPagination={true}
                    columns={columns}
                    setFilter={setFilterData}
                    filter={filterData}
                    pageInfo={{
                      totalPages: getTotalPages(weatherData, itemsPerPage),
                      currentPage: filterData.page,
                      totalCount: weatherData?.length,
                      limit:
                        10 > weatherData?.length ? weatherData?.length : 10,
                    }}
                    data={tableData}
                    customPageSize={10}
                    divClassName="table-card table-responsive text-start mt-3 overflow-hidden"
                    tableClass="table-hover table-nowrap align-middle mb-0 border table"
                    isBordered={false}
                    PaginationClass="align-items-center"
                    tableHeight={"50vh"}
                  />
                </Card.Body>
              </Card>
            )}
          </Modal.Body>
          <Modal.Footer className="my-0 py-0">
            <div className="d-flex gap-2 justify-content-center mb-2">
              {excelData?.length > 0 && (
                <Tooltips msg={"Download Excel"}>
                  <Button
                    disabled={isLoadingWeatherData || downloadingExcel}
                    onClick={handleExport}
                  >
                    {(isLoadingWeatherData || downloadingExcel) && (
                      <Spinner size="sm" />
                    )}
                    <i className="bi bi-file-earmark-excel-fill"></i>
                  </Button>
                </Tooltips>
              )}
              <Button
                type="button"
                variant="primary"
                disabled={isLoadingWeatherData}
                className="btn w-sm btn-hover"
                id="delete-record"
                onClick={handleWeatherHistoryFilter}
              >
                {isLoadingWeatherData && <Spinner size="sm" />}
                Find
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};
