import { Button, Offcanvas } from "react-bootstrap";
import { useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import {
  groupOptions,
  propertyOptions,
  redeckOptions,
  structureOptions,
  typeOptions,
  userOptions,
} from "modules/filters";

import { useGetMaterialOptionsQuery } from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { showErrorMsg, showWarningMsg } from "modules/helper";
import DatePicker from "react-datepicker";
import { range } from "lodash";
const FilterDrawer = ({
  isRight,
  invalidCoordinates,
  toggleRightCanvas,
  setMapFilters,
  mapFilters,
  setCustomerFilter,
  setCurrentPolygon,
}) => {
  document.title = "HailStorm  Filter";
  const [filters, setFilters] = useState(mapFilters);
  const { data: materialOption } = useGetMaterialOptionsQuery();
  let btnDisable = filters?.user?.value === "leads" ? true : false;
  const [dateRange, setDateRange] = useState([
    mapFilters["start date"],
    mapFilters["end date"],
  ]);
  const [startDate, endDate] = dateRange;
  const years = range(1990, new Date().getFullYear() + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "#00529D",
      ":hover": {
        borderColor: "#00529D",
        color: "white",
      },
      ":focus": {
        borderColor: "#00529D",
        color: "white",
      },
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused || isSelected ? "#6aabe7" : "white",
        ":active": {
          backgroundColor: "#6aabe7",
          borderColor: "#00529D",
          color: "black",
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#00529D",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      backgroundColor: "#00529D",
      color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      backgroundColor: "#00529D",
      ":hover": {
        backgroundColor: "#00529D",
        color: "white",
      },
    }),
  };
  const handelFilters = () => {
    if (invalidCoordinates?.length) {
      showWarningMsg("Invalid polygon");
      toggleRightCanvas(isRight);
      return;
    }
    if (
      !(mapFilters?.location || filters["start date"] || filters["end date"])
    ) {
      if (
        filters?.user?.value === "leads" ||
        filters?.user?.values === "leads"
      ) {
        showErrorMsg("Selection of filters is required");
      }
    }
    setMapFilters({ ...mapFilters, ...filters });
    setCustomerFilter(true);
    toggleRightCanvas(isRight);
  };
  // const handelMaterialFilter = (e) => {
  //   if (e.target.value.length <= 4) {
  //     setFilters({
  //       ...filters,
  //       material: {
  //         value: e.target.value.toUpperCase(),
  //         label: "material",
  //       },
  //     });
  //   }
  // };
  const handleRawDateFilter = (event) => {
    const raw = event.target.value;
    if (raw != null) {
      if (typeof raw === "string" && raw.length >= 13) {
        let rawStart = raw;
        let rawEnd = "";
        if (raw.includes(" - ")) {
          rawStart = raw.split(" - ")[0];
          rawEnd = raw.split(" - ")[1];

          let startDate = null;
          let endDate = null;
          startDate = new Date(rawStart);
          endDate = new Date(rawEnd);
          handleDateFilter([startDate, endDate]);
        }
      }
    }
  };
  const handleDateFilter = (date) => {
    if (
      !date[0] ||
      !date[1] ||
      isNaN(date[0].getMonth()) ||
      isNaN(date[1].getMonth())
    ) {
      setFilters({
        ...filters,
        "start date": "",
        "end date": "",
      });
      return;
    }
    setFilters({
      ...filters,
      "start date": date[0],
      "end date": date[1],
    });
    setDateRange(date);
  };

  const materials = async (e) => {
    if (!materialOption?.data) {
      return [];
    }
    const filterOptionData = await materialOption.data
      .filter((value) =>
        value?.material.toLowerCase().includes(e.toLowerCase()),
      )
      .map((value) => ({
        label: value.material,
        value: value.material,
      }));
    return filterOptionData;
  };
  const conditionCheck = { ...filters, user: "" };
  return (
    <Offcanvas
      id="offcanvasExample"
      show={isRight}
      onHide={() => toggleRightCanvas(isRight)}
      placement="end"
    >
      <Offcanvas.Header className="border-bottom" closeButton>
        <Offcanvas.Title id="offcanvasRightLabel">Map Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0 overflow-hidden">
        <div data-simplebar style={{ height: "calc(100vh - 112px)" }}>
          <div className="acitivity-timeline p-4">
            {/* <ReactTable /> */}
            {/* Users */}
            <div className="mb-3 ">
              <p className="text-muted m-0">Select Users</p>
              <Select
                closeMenuOnSelect={true}
                defaultValue={mapFilters.user}
                isMulti={false}
                options={userOptions}
                styles={customStyles}
                onChange={(e) => {
                  setFilters({ ...filters, user: e });
                }}
              />
            </div>
            {/* select date range */}
            <div className="mb-3">
              <p className="text-muted m-0">Select Date</p>
              <DatePicker
                placeholderText="Select date "
                className="p-2 rounded"
                selectsRange={true}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className="d-flex justify-content-center bg-primary ">
                    <Button
                      className="btn btn-primary"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </Button>
                    <select
                      className="bg-primary outline-none text-light border-0"
                      value={months[date.getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option
                          className="bg-light text-dark outline-0 border-0"
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                      className="bg-primary outline-0 text-light border-0"
                    >
                      {years.map((option) => (
                        <option
                          className="bg-light text-dark outline-0 border-0"
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    <Button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </Button>
                  </div>
                )}
                onChangeRaw={(e) => handleRawDateFilter(e)}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  if (update?.filter?.((e) => e)) {
                    handleDateFilter(update);
                    setDateRange(update);
                  } else {
                    handleDateFilter([]);
                    setDateRange([]);
                  }
                }}
                isClearable={true}
              />
              {/* <Flatpickr
                className="form-control border border-1 border-primary text-muted"
                placeholder="Select..."
                value={
                  [filters["start date"] ?? null, filters["end date"]] ?? null
                }
                onChange={(date) => {
                  handleDateFilter(date);
                }}
                isDisabled={btnDisable}
                options={{
                  mode: "range",
                  dateFormat: "m-d-Y",
                  defaultDate: new Date(),
                  // monthSelectorType:"static"
                }}
              /> */}
            </div>
            {/* property */}
            <div className="mb-3 ">
              <p className="text-muted m-0">Select Property</p>
              <Select
                closeMenuOnSelect={true}
                defaultValue={mapFilters.property}
                isDisabled={btnDisable}
                isMulti={false}
                options={propertyOptions}
                styles={customStyles}
                onChange={(e) => setFilters({ ...filters, property: e })}
              />
            </div>

            {/* type */}
            <div className="mb-3 ">
              <p className="text-muted m-0">Select Type</p>
              <Select
                closeMenuOnSelect={true}
                defaultValue={mapFilters.type}
                isMulti={false}
                options={typeOptions}
                styles={customStyles}
                isDisabled={btnDisable}
                onChange={(e) => setFilters({ ...filters, type: e })}
              />
            </div>

            {/* structure */}
            <div className="mb-3 ">
              <p className="text-muted m-0">Select Structure</p>
              <Select
                closeMenuOnSelect={true}
                defaultValue={mapFilters?.structure}
                isMulti={false}
                options={structureOptions}
                isDisabled={btnDisable}
                styles={customStyles}
                onChange={(e) => setFilters({ ...filters, structure: e })}
              />
            </div>
            {/* redeck */}
            <div className="mb-3 ">
              <p className="text-muted m-0">Select Redeck</p>
              <Select
                closeMenuOnSelect={true}
                defaultValue={mapFilters?.redeck}
                isMulti={false}
                options={redeckOptions}
                styles={customStyles}
                isDisabled={btnDisable}
                onChange={(e) => setFilters({ ...filters, redeck: e })}
              />
            </div>
            {/* group */}
            <div className="mb-3 ">
              <p className="text-muted m-0">Select Group</p>
              <Select
                closeMenuOnSelect={true}
                defaultValue={mapFilters.group}
                isDisabled={btnDisable}
                isMulti={false}
                options={groupOptions}
                styles={customStyles}
                onChange={(e) => setFilters({ ...filters, group: e })}
              />
            </div>
            {/* Material */}
            <div className="mb-3 d-flex flex-column ">
              <p htmlFor="material" className="text-muted m-0">
                Select Material
              </p>
              {/* <input
                id="material"
                type="text"
                placeholder="Material..."
                className="p-2 rounded"
                style={{
                  borderWidth: "1px",
                  borderColor: "#00529D",
                  outlineWidth: "1px",
                  outlineColor: "#7BADA2",
                }}
                value={filters?.material?.value?.toUpperCase()}
                onChange={(e) => handelMaterialFilter(e)}
              /> */}
              {/* <Select
                closeMenuOnSelect={true}
                defaultValue={mapFilters.material}
                isMulti={false}
                onInputChange={(e) => {
                  materials(e);
                }}
                options={materials}
                styles={customStyles}
                onChange={(e) => setFilters({ ...filters, material: e })}
              /> */}
              <AsyncSelect
                name="model"
                styles={customStyles}
                cacheOptions
                isClearable
                isSearchable={true}
                defaultOptions
                isDisabled={btnDisable}
                defaultValue={mapFilters.material}
                loadOptions={materials}
                onChange={(e) => {
                  if (e?.value) {
                    setFilters({ ...filters, material: e });
                  } else {
                    setFilters({ ...filters, material: "" });
                  }
                }}
              />
            </div>
            <div className="d-flex gap-1">
              <Button
                variant="primary"
                onClick={() => {
                  handelFilters();
                }}
                disabled={
                  filters?.user?.value === "leads" ||
                  filters?.user?.values === "leads"
                    ? !(filters?.["start date"] || filters?.location)
                    : Object?.values(conditionCheck)?.filter(
                        (e) => e?.value !== null && e !== "",
                      )?.length <= 0
                }
              >
                Apply{" "}
              </Button>
              <Button
                variant="danger"
                onClick={() => toggleRightCanvas(isRight)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default FilterDrawer;
