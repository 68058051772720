export const getPolygonColor = (hailSize) => {
    const colors = {
        0.25: "#fce903",
        0.5: "#ffdd00",
        0.75: "#fcd203",
        1: "#fcc403",
        1.5: "#fcbd03",
        1.25: "#fcb403",
        1.75: "#fc9d03",
        2: "#fc8a03",
        2.25: "#fc7e03",
        2.5: "#fc7703",
        2.75: "#fc6e03",
        3: "#fc6703",
        3.25: "#fc6003",
        3.5: "#fc5703",
        3.75: "#fc5003",
        4: "#fc4703",
        4.25: "#fc4203",
        4.5: "#fd3402",
        4.75: "#fd2802",
        5: "#fd1f02",
        5.25: "#f70800",
        5.5: "#fe1301",
        6: "#ff0000",
    };

    return colors[hailSize];
};

export const formatDate = (date) => {
    const dd = date?.getDate() < 10 ? "0" + date?.getDate() : date?.getDate();
    const mm =
        date?.getMonth() + 1 < 10
            ? "0" + (date?.getMonth() + 1)
            : date?.getMonth() + 1;
    return `${mm}/${dd}/${date?.getFullYear()}`
}
