import { Nav, Offcanvas, Tab } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import {
  convertToExcel,
  isValidArray,
  setBodyForCustomerApi,
  showWarningMsg,
} from "modules/helper";
import {
  useGetCustomersMutation,
  useGetLeadsMutation,
} from "reduxStore/rtk/EndPoints/SageEndPoint/sageSlice";
import { UserDrawerComponent } from "./UserDrawerComponent";
import { formatDateCustomerFilter } from "../CollumnFormator";
import { useState } from "react";
import { UserDrawerFooter } from "./UserDrawerFooter";
const UserDrawer = ({
  showUserCanvas,
  toggleUserCanvas,
  leadsData,
  customerData,
  setDrawerChange,
  setMarker,
  mapFilters,
  getCustomers,
  isCustomerDataLoading,
  isLeadLoading,
  getLead,
  setLeadData,
  onLoadMap,
}) => {
  const [getCustomerData, { isLoading: isDownloading }] =
    useGetCustomersMutation();
  const [getLeads, { isLoading: isLeadDataLoading }] = useGetLeadsMutation();
  const downloadCustomerData = async () => {
    try {
      let data = setBodyForCustomerApi(mapFilters);
      if (Object.keys(data).length > 0) {
        let response = await getCustomerData({
          url: "sage/customers",
          export: true,
          data,
        });
        if (response.data.type === "success") {
          convertToExcel(response?.data?.data);
        }
      }
    } catch (error) {
      showWarningMsg();
    }
  };
  const downloadLeadsData = async () => {
    try {
      let leadsData = {};
      if (mapFilters?.location) {
        leadsData = {
          ...leadsData,
          location: { ...mapFilters?.location },
        };
      }
      if (mapFilters["start date"] !== "" && mapFilters["end date"] !== "") {
        let startDate = formatDateCustomerFilter(mapFilters["start date"]);
        let endDate = formatDateCustomerFilter(mapFilters["end date"]);
        leadsData = {
          ...leadsData,
          // eslint-disable-next-line
          ["startDate"]: startDate,
          // eslint-disable-next-line
          ["endDate"]: endDate,
        };
      }
      let response = await getLeads({
        url: "sage/leads",
        export: true,
        data: leadsData,
      });
      if (response.data.type === "success") {
        convertToExcel(response?.data?.data, "leads");
      }
    } catch (error) {
      showWarningMsg();
    }
  };
  const customerValidate =
    mapFilters?.user?.value === "customer" ||
    mapFilters?.user?.value === "all" ||
    mapFilters?.user?.values === "all" ||
    mapFilters?.user?.values === "customer";
  const leadValidate =
    mapFilters?.user?.value === "leads" ||
    mapFilters?.user?.values === "leads" ||
    mapFilters?.user?.value === "all" ||
    mapFilters?.user?.values === "all";
  const [currentTab, setCurrentTab] = useState(
    customerValidate && isValidArray(customerData?.data?.customers)
      ? "customer"
      : "leads",
  );
  return (
    <SimpleBar
      autoHide={false}
      data-simplebar-track="primary"
      style={{ maxHeight: "100vh" }}
    >
      <Offcanvas
        show={showUserCanvas}
        onHide={() => toggleUserCanvas(showUserCanvas)}
        placement="end"
      >
        <Tab.Container
          defaultActiveKey={
            customerValidate && isValidArray(customerData?.data?.customers)
              ? "border-navs-customer"
              : "border-navs-leads"
          }
        >
          <Offcanvas.Header className="border-bottom  text-light" closeButton>
            <Nav
              as="ul"
              variant="pills"
              className="nav-customs nav-danger  "
              role="tablist"
              style={{}}
            >
              <Nav.Item as="li">
                <Nav.Link
                  eventKey="border-navs-customer"
                  onClick={() => {
                    setCurrentTab("customer");
                    let data = setBodyForCustomerApi(mapFilters);
                    getCustomers({
                      url: `sage/customers?page=1`,
                      data: data,
                    });
                  }}
                  disabled={
                    !isValidArray(customerData?.data?.customers) ||
                    !customerValidate
                  }
                  style={{
                    cursor: isValidArray(customerData?.data?.customers)
                      ? "pointer"
                      : "not-allowed",
                  }}
                >
                  Customers
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                  eventKey="border-navs-leads"
                  onClick={() => {
                    setCurrentTab("leads");
                    onLoadMap();
                    if (isValidArray(leadsData?.data?.leads)) {
                      let leadsData = {};
                      if (mapFilters?.location) {
                        leadsData = {
                          ...leadsData,
                          location: { ...mapFilters?.location },
                        };
                      }
                      if (
                        mapFilters["start date"] !== "" &&
                        mapFilters["end date"] !== ""
                      ) {
                        let startDate = formatDateCustomerFilter(
                          mapFilters["start date"],
                        );
                        let endDate = formatDateCustomerFilter(
                          mapFilters["end date"],
                        );
                        leadsData = {
                          ...leadsData,
                          // eslint-disable-next-line
                          ["startDate"]: startDate,
                          // eslint-disable-next-line
                          ["endDate"]: endDate,
                        };
                      }
                      getLead({
                        url: `sage/leads?page=1`,
                        data: leadsData,
                      });
                    }
                  }}
                  disabled={
                    !isValidArray(leadsData?.data?.leads) || !leadValidate
                  }
                  style={{
                    cursor: isValidArray(leadsData?.data?.leads)
                      ? "pointer"
                      : "not-allowed",
                  }}
                >
                  Leads
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Offcanvas.Header>
          <Offcanvas.Body data-simplebar-track="primary" className="p-0">
            <SimpleBar
              autoHide={false}
              data-simplebar-track="primary"
              style={{ maxHeight: "calc(100vh - 200px)" }}
            >
              <Tab.Content className="text-muted">
                <Tab.Pane eventKey="border-navs-customer" >
                  <UserDrawerComponent
                    customerData={customerData?.data?.customers}
                    setMarker={setMarker}
                    isDownloading={isDownloading}
                    pageData={{
                      currentPage: customerData?.data?.currentPage,
                      pageLimit: customerData?.data?.pageLimit,
                      total: customerData?.data?.total,
                    }}
                    downloadCustomerData={downloadCustomerData}
                    downloadLeadsData={downloadLeadsData}
                    isLeadDataLoading={isLeadDataLoading}
                    identity={"Customer"}
                    isCustomerDataLoading={isCustomerDataLoading}
                    setDrawerChange={setDrawerChange}
                    setLeadData={setLeadData}
                    isLeadLoading={isLeadLoading}
                    onLoadMap={onLoadMap}
                    getCustomers={getCustomers}
                    getLead={getLead}
                    mapFilters={mapFilters}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="border-navs-leads">
                  <UserDrawerComponent
                    customerData={leadsData?.data?.leads}
                    setMarker={setMarker}
                    isDownloading={isDownloading}
                    pageData={{
                      currentPage: leadsData?.data?.currentPage,
                      pageLimit: leadsData?.data?.pageLimit,
                      total: leadsData?.data?.total,
                    }}
                    downloadCustomerData={downloadCustomerData}
                    downloadLeadsData={downloadLeadsData}
                    isLeadDataLoading={isLeadDataLoading}
                    onLoadMap={onLoadMap}
                    isCustomerDataLoading={isCustomerDataLoading}
                    isLeadLoading={isLeadLoading}
                    identity={"Leads"}
                    getCustomers={getCustomers}
                    setDrawerChange={setDrawerChange}
                    mapFilters={mapFilters}
                    getLead={getLead}
                    setLeadData={setLeadData}
                  />
                </Tab.Pane>
              </Tab.Content>
            </SimpleBar>
          </Offcanvas.Body>
          <UserDrawerFooter
            pageData={
              currentTab === "customer"
                ? {
                    currentPage: customerData?.data?.currentPage,
                    pageLimit: customerData?.data?.pageLimit,
                    total: customerData?.data?.total,
                  }
                : {
                    currentPage: leadsData?.data?.currentPage,
                    pageLimit: leadsData?.data?.pageLimit,
                    total: leadsData?.data?.total,
                  }
            }
            identity={currentTab === "customer" ? "Customer" : "Leads"}
            getCustomers={getCustomers}
            getLead={getLead}
            isCustomerDataLoading={isCustomerDataLoading}
            isLeadLoading={isLeadLoading}
            mapFilters={mapFilters}
            setLeadData={setLeadData}
            customerData={customerData}
            leadsData={leadsData}
          />
        </Tab.Container>
      </Offcanvas>
    </SimpleBar>
  );
};
export default UserDrawer;
