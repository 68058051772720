import React, { Fragment, useEffect, useRef } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button } from "react-bootstrap";
import { DefaultColumnFilter } from "components/common/Tabledata/Filter";

import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const TableContainer = ({
  columns,
  data = [],
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isGlobalFilter,
  isProductsFilter,
  isPagination,
  customPageSize,
  iscustomPageSize,
  // customPageSizeOptions,
  SearchPlaceholder,
  PaginationClass,
  selected,
  setSelected,
  pageInfo = 0,
  columnFilter,
  filter,
  setFilter,
  // setColumnFilter,
  columnChnageFilter = () => {},
  tableHeight,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    // state,
    // preGlobalFilteredRows,
    // setGlobalFilter,
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      manualSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize ? customPageSize : data.length,
        // sortBy: [
        //   {
        //     id: "updated_at",
        //     desc: true,
        //   },
        // ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );
  useEffect(() => {
    setPageSize(filter?.limit ?? 100);
  }, [filter, setPageSize]);
  const simpleBarRef = useRef(null);

  useEffect(() => {
    // Scroll to top when the component re-renders
    if (simpleBarRef.current) {
      simpleBarRef.current.getScrollElement().scrollTop = 0;
    }
  }, [filter?.page]);
  const columnSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <span>&#9650;</span>
      ) : (
        <span>&#9660;</span>
      )
    ) : (
      ""
    );
  };
  // function used to sort the column data ascending/descending
  const handleColumn = (column) => {
    if (column?.isSortedDesc === undefined && column?.isSorted === false) {
      return setFilter({
        ...filter,
        sortBy: {
          name: column.id === "name" ? "desc" : "",
          email: column.id === "email" ? "desc" : "",
          createdAt: column.id === "createdAt" ? "desc" : "",
          date: column.id === "Date" ? "desc" : "",
          hailSize: column.id === "HailSize" ? "desc" : "",
        },
      });
    }

    if (column?.isSortedDesc === false && column?.isSorted === true) {
      return setFilter({
        ...filter,
        sortBy: {
          name: column.id === "name" ? "asc" : "",
          email: column.id === "email" ? "asc" : "",
          createdAt: column.id === "createdAt" ? "asc" : "",
          date: column.id === "Date" ? "asc" : "",
          hailSize: column.id === "HailSize" ? "asc" : "",
        },
      });
    }

    if (column.isSorted === true && column?.isSortedDesc === true) {
      return setFilter({
        ...filter,
        sortBy: {
          name: "",
          email: "",
          createdAt: "",
          date: "",
          hailSize: "",
        },
      });
    }
  };

  const total = pageInfo?.totalPages ?? "";
  const onChangePage = (pageValue) => {
    if (pageValue) {
      setFilter({ ...filter, page: pageValue });
    }
  };

  const handleNextPage = () => {
    if (filter?.page) {
      setFilter({ ...filter, page: filter?.page + 1 });
    }
  };
  const handlePreviousPage = () => {
    if (filter?.page) {
      setFilter({ ...filter, page: filter?.page - 1 });
    }
  };
  const handleFirstPage = () => {
    setSelected({ ...selected, page: 1 });
  };
  const handleLastPage = () => {
    setSelected({ ...selected, page: total });
  };
  const pageCount = pageInfo?.totalPages;
  const range = 2; // Number of pages to show before and after the current page
  const startPage = Math.max(pageInfo?.currentPage - range, 0);
  const endPage = Math.min(pageInfo?.currentPage + range, pageCount - 1);

  const pagesToShow = [];

  if (startPage > 0) {
    pagesToShow.push(0);
    if (startPage > 1) pagesToShow.push("...");
  }

  for (let i = startPage; i <= endPage; i++) {
    pagesToShow.push(i);
  }

  if (endPage < pageCount - 1) {
    if (endPage < pageCount - 2) pagesToShow.push("...");
    pagesToShow.push(pageCount - 1);
  }
  return (
    <Fragment>
      <div className={divClassName ? divClassName : "table-responsive"}>
        <SimpleBar
          ref={simpleBarRef}
          style={{
            maxHeight: `${
              (isPagination === true || isPagination === false) && tableHeight
                ? tableHeight
                : "300px"
            }`,
          }}
        >
          <Table
            {...getTableProps()}
            className={tableClass}
            bordered={isBordered}
          >
            <thead
              className={`${theadClass} `}
              style={{
                position: "sticky",
                top: "0px",
                zIndex: "1000",
              }}
            >
              {headerGroups?.map((headerGroup, headerIndex) => (
                <tr key={headerIndex} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((columns, columnIndex) => (
                    <th
                      key={columnIndex}
                      className="user-select-none"
                      {...columns.getHeaderProps({
                        onClick: () => {
                          if (columns.isSortable) {
                            handleColumn(columns);
                          }
                        },
                      })}
                      style={{
                        border: `${columns.style && "1px solid black"}`,
                        textAlign: columns.style && columns.style.textAlign,
                        width: columns?.width && columns?.width,
                      }}
                    >
                      {columns.isSortable && (
                        <div {...columns.getSortByToggleProps()}>
                          {columns.render("Header")}

                          {columns.isSortable &&
                            columnSortingIndicator(columns)}
                        </div>
                      )}
                      {!columns.isSortable && (
                        <span>{columns.render("Header")}</span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {page?.length > 0 && (
              <tbody {...getTableBodyProps()}>
                {page?.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells?.map((cell) => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            )}
          </Table>
          {(!page?.length > 0 || data.length === 0) && (
            <div className="text-center p-2 ">
              <h5>No Data Found</h5>
            </div>
          )}
        </SimpleBar>
      </div>
      {/* table footer, pagination and number of results */}
      {isPagination && page?.length > 0 && total > 0 ? (
        <div>
          {selected?.page ? (
            <Row className={PaginationClass} id="pagination-element">
              <Col md={2}>
                <div className="text-muted">
                  Showing
                  <span className="fw-semibold ms-1">
                    {data?.length}
                  </span> of{" "}
                  <span className="fw-semibold">{pageInfo?.totalPages}</span>{" "}
                  Results
                </div>
              </Col>
              <Col md={2}>
                <div className="text-muted d-flex gap-2">
                  <span>Total</span>
                  <span>Records</span>
                  <span className="fw-semibold">
                    {pageInfo?.totalCount}
                  </span>{" "}
                </div>
              </Col>
              <div className="col-sm-auto ms-auto">
                <ul className="pagination mb-0 d-flex gap-2 ">
                  <li
                    className={
                      pageInfo?.currentPage === 1
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-prev"
                      onClick={() => {
                        handleFirstPage();
                      }}
                    >
                      First Page
                    </Link>
                  </li>
                  <li
                    className={
                      pageInfo?.currentPage === 1
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-prev"
                      onClick={() => {
                        handlePreviousPage();
                      }}
                    >
                      <i className="ri-arrow-left-s-line me-1 align-middle ri-xl"></i>
                    </Link>
                  </li>

                  <li
                    className={
                      pageInfo?.currentPage === total
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-next"
                      onClick={() => {
                        handleNextPage();
                      }}
                    >
                      <i className=" ri-arrow-right-s-line me-1 align-middle ri-xl"></i>
                    </Link>
                  </li>
                  <li
                    className={
                      pageInfo?.currentPage === total
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link pagination-prev"
                      onClick={() => {
                        handleLastPage();
                      }}
                    >
                      Last Page
                    </Link>
                  </li>
                </ul>
              </div>
            </Row>
          ) : (
            <Row className="align-items-center mt-2 py-2 px-2 gy-2 text-center text-sm-start">
              <div className="col-sm">
                <div className="text-muted">
                  Showing <span className="fw-semibold">{data?.length}</span> of{" "}
                  <span className="fw-semibold">{pageInfo?.totalCount}</span>{" "}
                  Results
                </div>
              </div>
              <div className="col-sm-auto">
                <ul className="pagination pagination-separated mb-0 justify-content-center justify-content-sm-start">
                  <li
                    className={
                      filter?.page === 1
                        ? "page-item disabled"
                        : "pagination pagination-separated mb-0 justify-content-center justify-content-sm-start"
                    }
                    onClick={() => {
                      if (filter?.page !== 1) {
                        handlePreviousPage();
                      }
                    }}
                  >
                    <Button variant="link" className="page-link">
                      Previous
                    </Button>
                  </li>
                  {pagesToShow?.map((item, key) =>
                    typeof item === "number" ? (
                      <li className="page-item" key={key}>
                        <Link
                          to="#"
                          className={
                            filter?.page - 1 === item
                              ? "page-link active"
                              : "page-link"
                          }
                          onClick={() => onChangePage(item + 1)}
                        >
                          {item + 1}
                        </Link>
                      </li>
                    ) : (
                      <li className="page-item disabled" key={key}>
                        <span className="page-link">...</span>
                      </li>
                    )
                  )}
                  <li
                    className={
                      filter?.page === pageInfo?.totalPages
                        ? "page-item disabled"
                        : "page-item"
                    }
                    onClick={() => {
                      if (filter?.page !== pageInfo?.totalPages) {
                        handleNextPage();
                      }
                    }}
                  >
                    <Button variant="link" className="page-link">
                      Next
                    </Button>
                  </li>
                </ul>
              </div>
            </Row>
          )}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

// TableContainer.propTypes = {
//   preGlobalFilteredRows: PropTypes,
// };

export default TableContainer;
