import PageTitle from "components/common/PageTitle";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { UserList } from "./userList";
import { useState } from "react";
import UserModal from "components/common/UserModal";

const Users = () => {
  /* userFilter State
   * Keys in userFilter are used to set header parameter while calling api
   * while calling the /users endpoint, we pass the filter in payload, and according to the filter header params are decided
   */
  const [userFilter, setUserFilter] = useState({
    url: "/users",
    name: "",
    sortBy: {
      name: "",
      email: "",
    },
    limit: 10,
    page: 1,
    email: "",
    isActive: "",
  });
  const [addUserModal, setAddUserModal] = useState(false); // according to the value of this state we will show the Modal to add user in the list
  const handleAddUser = () => {
    setAddUserModal(!addUserModal);
  };
  const onChangeInSelect = (event, totalPages) => {
    // setting the page limit
    if (event?.target?.value) {
      setUserFilter({ ...userFilter, limit: event?.target?.value, page: 1 });
    }
  };
  return (
    <>
      <PageTitle title="Users" />
      <Card>
        <Card.Header>
          <Row>
            <Col xl={6}>
              <Button onClick={handleAddUser}>
                Add User <i className="las la-plus"></i>
              </Button>
            </Col>

            <Col xl={3}>
              <div className="search-box">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Search by name or email"
                  onChange={(e) => {
                    //  taking the value form input box and setting filter , to search users according to input given
                    //  searching is done based on name and email of user
                    setUserFilter({ ...userFilter, name: e.target.value });
                  }}
                />
                <i className="las la-search search-icon"></i>
              </div>
            </Col>
            <Col xl={2}>
              <Form.Select
                aria-label=".form-select-sm example"
                onChange={(e) => {
                  // isActive : true/false , used to find user based on status active/inactive/all(both)
                  setUserFilter({ ...userFilter, isActive: e.target.value });
                }}
              >
                <option value="">Choose status</option>
                <option value="">All</option>
                <option value={true}>Active</option>
                <option value={false}>In Active</option>
              </Form.Select>
            </Col>
            <Col xl={1}>
              <div className="">
                <select
                  className="form-select"
                  value={userFilter?.limit}
                  // show number of users per page
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="overflow-auto">
          {/* render the user table , filter are passed as prop so that we can change filter within userList component */}
          <UserList userFilter={userFilter} setUserFilter={setUserFilter} />
        </Card.Body>
      </Card>
      <UserModal
        modalTitle="Add User"
        isShow={addUserModal}
        handleClose={() => setAddUserModal(false)}
      />
    </>
  );
};

export default Users;
