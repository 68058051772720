import { Card, Col, Form, Nav, Row, Tab, Modal } from "react-bootstrap";
import MapComponent from "components/common/Maps/GoogleMap/MapComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import React, { useEffect, useRef, useState } from "react";
import {
  useCreateNotificationMutation,
  useGetNotificationByIdMutation,
  useUpdateNotificationMutation,
} from "reduxStore/rtk/EndPoints/NotificationEndPoint/NotificationSlice";
import { getArrayDepth, showErrorMsg, showSuccessMsg } from "modules/helper";
import Slider from "rc-slider";
import { formatTime } from "./CollumnFormator";

/**common component to update and add notification
 * id prop is used to decide whether to update or add notification.
 */
const NotificationForm = ({
  modalTitle,
  isShow,
  handleClose,
  id,
  setEditId = null,
}) => {
  const [addNotification] = useCreateNotificationMutation(); // rtk method to create notification
  const [getNotification, data] = useGetNotificationByIdMutation(); // rtk method to get list of all notifications

  /**
   * map filters
   * these filters are used to pass in body while calling api
   * initial filter is set based on id prop
   * If id prop is present -> then we have to update notification map filter have some values
   * otherwise -> giving null values to initialMapFilters.
   */
  const initialMapFilters = {
    hailSize: id ? data?.data?.hailSize : null,
    user: { values: "customer", label: "Customer" },
    name: id ? data?.data?.name : "",
    time: id ? data?.data?.time : "",
    area: id ? data?.data?.area : {},
    location: id ? data?.data?.area : {},
  };
  const [hailSize, setHailSize] = useState(0);
  const [invalidCoordinates, setInvalidCoordinates] = useState([]);
  const [updateNotification] = useUpdateNotificationMutation(); // rtk method to update existing notification
  const [currentPolygon, setCurrentPolygon] = useState(null); // store the polygon coordinate which will be shown on map
  const mapRef = useRef();
  const [circleData, setCircleData] = useState({});
  const [polygonData, setPolygonData] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [mapFilters, setMapFilters] = useState(initialMapFilters);
  const [activeArrowTab, setactiveArrowTab] = useState(1); // nav tabs used in notification form.
  useEffect(() => {
    if (id) {
      // call get noti API
      getNotification({ url: `notifications/${id}` });
    }
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (data?.data?.hailSize) {
      // call get noti API
      setHailSize(data?.data?.hailSize);
      setMapFilters({
        ...mapFilters,
        name: data?.data?.name,
        time: data?.data?.time,
        area: data?.data?.area,
        location: data?.data?.area,
        hailSize: data?.data?.hailSize,
      });
    }
    // eslint-disable-next-line
  }, [data?.data?.hailSize]);
  const formik = useFormik({
    enableReinitialize: true,
    //setting up initial values based on update or add notification
    initialValues: {
      hailSize: id ? data?.data?.hailSize : null,
      name: id ? data?.data?.name : "",
      time: id ? data?.data?.time : "",
      area: id ? data?.data?.area : {},
      location: id ? data?.data?.area : {},
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      hailSize: Yup.number().min(0.1).required("Please Enter Hail Size"),
      time: Yup.string().required("Please Select Time"),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  const closeModal = () => {
    handleClose();
    formik.resetForm();
    setactiveArrowTab(1);
    if (setEditId) {
      setEditId("");
    }
    setHailSize(0);
  };
  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={closeModal}
        style={{ display: "block" }}
        tabIndex={-1}
        size="xl"
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">{modalTitle}</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            {/* <Card> */}
            <Card.Body className="form-steps">
              <Form onSubmit={formik.handleSubmit}>
                <Tab.Container activeKey={activeArrowTab}>
                  <div className="step-arrow-nav mb-4">
                    <Nav
                      as="ul"
                      variant="pills"
                      className="custom-nav nav-justified"
                    >
                      <Nav.Item as="li">
                        <Nav.Link
                          as="button"
                          eventKey="1"
                          onClick={(e) => {
                            e.preventDefault();
                            setactiveArrowTab(1);
                          }}
                        >
                          Notification
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link
                          as="button"
                          eventKey="2"
                          onClick={(e) => {
                            if (
                              formik.values?.name &&
                              formik.values?.hailSize &&
                              formik.values?.time
                            ) {
                              e.preventDefault();
                              setactiveArrowTab(2);
                            }
                          }}
                        >
                          Select Area
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>

                  <Tab.Content>
                    {/* tab 1 : notification information
                     * name: notification name
                     * hailsize : selecting hailsize
                     *  time : time when notification is to be sent
                     */}
                    <Tab.Pane eventKey="1">
                      <div>
                        <Row>
                          <Col lg={8}>
                            <div className="mb-3">
                              <Form.Label htmlFor="name">
                                Name<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Enter Name"
                                value={formik.values.name || ""}
                                onChange={(e) => {
                                  setMapFilters({
                                    ...mapFilters,
                                    name: e.target.value,
                                  });
                                  formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                isInvalid={
                                  !!formik.errors.name && !!formik.touched.name
                                }
                              />
                              {formik.errors.name && formik.touched.name ? (
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-block"
                                >
                                  {formik.errors.name}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              {/* <Form.Control
                                                                type="text"
                                                                id="hailSize"
                                                                name="hailSize"
                                                                placeholder="Enter Hail Size"
                                                                value={formik.values.hailSize || ""}
                                                                onChange={(e) => {
                                                                    setMapFilters({ ...mapFilters, hailSize: e.target.value });
                                                                    formik.handleChange(e);
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.hailSize}
                                                            /> */}
                              <div className="d-flex my-2 justify-content-between">
                                <Form.Label htmlFor="hailSize">
                                  Hail Size
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div>{hailSize}</div>
                              </div>
                              <Slider
                                min={0}
                                max={6}
                                id="hailSize"
                                name="hailSize"
                                value={hailSize}
                                step={0.25}
                                className="text-primary"
                                onChange={(value) => {
                                  setHailSize(value);
                                  setMapFilters({
                                    ...mapFilters,
                                    hailSize: value,
                                  });
                                }}
                                onChangeComplete={(nextValue) => {
                                  formik.setFieldValue("hailSize", nextValue);
                                }}
                                styles={{
                                  track: {
                                    background: `linear-gradient(to right, yellow, red)`,
                                  },
                                }}
                                isInvalid={
                                  !!formik.errors.hailSize &&
                                  !!formik.touched.hailSize
                                }
                              />
                              {formik.errors.hailSize &&
                              formik.touched.hailSize ? (
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-block"
                                >
                                  {formik.errors.hailSize}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Form.Label htmlFor="time">
                                Time<span className="text-danger">*</span>
                              </Form.Label>
                              {/* <Flatpickr
                                                                id="time"
                                                                name="time"
                                                                className="form-control"
                                                                placeholder="Enter Date/Time"
                                                                // value={formik.values.time}
                                                                onChange={(val) => {
                                                                    setMapFilters({ ...mapFilters, time: val?.[0] });
                                                                    formik.setFieldValue("time", val?.[0])
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                isInvalid={!!formik.errors.time}
                                                                options={{
                                                                    enableTime: true,
                                                                    dateFormat: "Y-m-d H:i",
                                                                }}
                                                            /> */}
                              <Flatpickr
                                id="time"
                                name="time"
                                placeholder="Enter Time"
                                className="form-control"
                                value={formik?.values?.time}
                                onChange={(val) => {
                                  setMapFilters({
                                    ...mapFilters,
                                    time: val?.[0],
                                  });
                                  formik.setFieldValue("time", val?.[0]);
                                }}
                                onBlur={formik.handleBlur}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i",
                                }}
                              />
                              {formik.errors.time && formik.touched.time ? (
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-block"
                                >
                                  {formik.errors.time}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="submit"
                          className="btn btn-success btn-label right ms-auto nexttab nexttab"
                          onClick={() => {
                            if (
                              formik.values?.name &&
                              formik.values?.hailSize &&
                              formik.values?.time
                            ) {
                              setactiveArrowTab(2);
                            }
                          }}
                        >
                          <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                          Next
                        </button>
                      </div>
                    </Tab.Pane>

                    {/* tab :2 selecting area to be notified on map */}
                    <Tab.Pane eventKey="2">
                      <div
                        id="gmaps-markers"
                        className="gmaps"
                        style={{ position: "relative", height: "500px" }}
                      >
                        <MapComponent
                          mapStyle={{
                            width: "100%",
                            height: "500px",
                          }}
                          mapRef={mapRef}
                          setInvalidCoordinates={setInvalidCoordinates}
                          circleData={circleData}
                          isFilterApplied={isFilterApplied}
                          setIsFilterApplied={setIsFilterApplied}
                          setCircleData={setCircleData}
                          polygonData={polygonData}
                          setPolygonData={setPolygonData}
                          searchedLocation={searchedLocation}
                          setSearchedLocation={setSearchedLocation}
                          mapFilters={mapFilters}
                          setMapFilters={setMapFilters}
                          notification={formik}
                          currentPolygon={currentPolygon}
                          setCurrentPolygon={setCurrentPolygon}
                        />
                      </div>
                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          className="btn btn-light btn-label previestab"
                          onClick={() => setactiveArrowTab(1)}
                        >
                          <i className="ri-arrow-left-line label-icon align-middle fs-lg me-2"></i>{" "}
                          Back
                        </button>
                        <button
                          type="button"
                          disabled={
                            !mapFilters?.location?.coordinates?.length > 0 ||
                            invalidCoordinates?.length
                          }
                          className="btn btn-success btn-label right ms-auto nexttab nexttab"
                          onClick={async () => {
                            if (
                              mapFilters?.name &&
                              mapFilters?.time &&
                              mapFilters?.hailSize
                            ) {
                              let payload = {
                                name: mapFilters?.name,
                                hailSize: mapFilters?.hailSize,
                                time: formatTime(mapFilters?.time),
                                area: mapFilters?.location,
                              };
                              if (id) {
                                let res = await updateNotification({
                                  url: `notifications/${id}`,
                                  data:
                                    getArrayDepth(
                                      payload?.area?.coordinates,
                                    ) === 3
                                      ? {
                                          ...payload,
                                          area: {
                                            ...payload?.area,
                                            coordinates:
                                              payload?.area?.coordinates?.[0],
                                          },
                                        }
                                      : payload,
                                });
                                if (res?.data?.type === "success") {
                                  showSuccessMsg(res?.data?.message);
                                  setactiveArrowTab(3);
                                  closeModal();
                                  setHailSize(0);
                                } else {
                                  showErrorMsg(res?.errors?.message);
                                }
                              } else {
                                let res = await addNotification({
                                  url: "notifications",
                                  data: payload,
                                });
                                if (res?.data?.type === "success") {
                                  showSuccessMsg(res?.data?.message);
                                  setactiveArrowTab(3);
                                  closeModal();
                                  setHailSize(0);
                                } else {
                                  showErrorMsg(res?.errors?.message);
                                }
                              }
                            } else {
                              showErrorMsg(
                                "Name, Hail size and Time is required",
                              );
                            }
                          }}
                        >
                          <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                          Submit
                        </button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <div className="text-center">
                        <div className="avatar-md mt-5 mb-4 mx-auto">
                          <div className="avatar-title bg-light text-success display-4 rounded-circle">
                            <i className="ri-checkbox-circle-fill"></i>
                          </div>
                        </div>
                        <h5>Well Done !</h5>
                        <p className="text-muted">
                          Notification Added Successfully
                        </p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Form>
            </Card.Body>
            {/* </Card> */}
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default NotificationForm;
