import { firstLetterUpperCase } from "modules/helper";
import { Button, Card, Spinner } from "react-bootstrap";
import Tooltips from "../tooltipBox/Tooltip";
import React from "react";
export const UserDrawerComponent = ({
  customerData,
  setMarker,
  isDownloading,
  pageData,
  downloadCustomerData,
  downloadLeadsData,
  isLeadDataLoading,
  identity,
  getCustomers,
  mapFilters,
  setLeadData,
  isCustomerDataLoading,
  setDrawerChange,
  onLoadMap,
  isLeadLoading,
  getLead,
}) => {
  const downloadData = () => {
    if (identity === "Leads") {
      downloadLeadsData();
    } else {
      downloadCustomerData();
    }
  };

  if (isCustomerDataLoading || isLeadLoading) {
    return <UserDrawerSkeleton name="customer" />;
  }

  return (
    <div style={{ height: "100vh" }}>
      {customerData?.map((customer, index) => (
        <Card className="m-2 border border-1 border-primary" key={index}>
          <Card
            className="card-body mb-0"
            onClick={() => {
              setMarker(customer);
            }}
          >
            <div className="d-flex mb-4 align-items-center">
              <div className="flex-shrink-0">
                <i
                  className="bi bi-person-circle"
                  style={{
                    color: "#00529D",
                    fontSize: "xx-large",
                  }}
                ></i>
              </div>
              <div className="flex-grow-1 ms-2">
                <Tooltips msg={"Click to locate on Map"} direction={"top"}>
                  <h5 className="card-title mb-1" style={{ cursor: "pointer" }}>
                    {customer?.name}{" "}
                    <Tooltips
                      msg={identity === "Leads" ? "Lead No" : "Customer Id"}
                      direction={"right"}
                    >
                      <span
                        className="badge-label badge bg-success"
                        style={{ fontSize: "x-small" }}
                      >
                        {customer?.customerId ?? customer?.leadId}
                      </span>
                    </Tooltips>
                  </h5>
                </Tooltips>
                <Tooltips msg={"Job Id"} direction={"left"}>
                  <p className="text-muted mb-0">{customer?.jobid}</p>
                </Tooltips>
              </div>
            </div>
            <Tooltips msg={"Location"} direction={"left"}>
              <h6 className="mb-2">
                <i
                  className=" ri-user-location-line"
                  style={{ color: "#00529D" }}
                ></i>
                &nbsp;
                {customer.address?.toLowerCase()},
              </h6>
            </Tooltips>
            <Tooltips msg={"Location"} direction={"left"}>
              <h6 style={{ marginLeft: "20px" }}>
                {firstLetterUpperCase(customer?.city)},&nbsp;
                {customer?.state?.toUpperCase()},&nbsp;
                {customer?.zipcode}
              </h6>
            </Tooltips>
            <Tooltips msg={"Logged Date"} direction={"left"}>
              <h6 className="mt-2 mb-0">
                <i
                  className="bi bi-calendar-date"
                  style={{ color: "#00529D" }}
                ></i>
                &nbsp;
                {customer.loggedDate}
              </h6>
            </Tooltips>
            <hr />
            <p className="card-text text-muted m-0 ">
              <i className="bx bx-user-check " style={{ color: "#00529D" }}></i>
              &nbsp; Assigned To
            </p>
            <h6 className=" mb-2">
              {identity === "Leads"
                ? customer?.assignedTo
                : customer.employee1
                  ? customer.employee1
                  : customer.employee2
                    ? customer.employee2
                    : customer.employee3}
            </h6>
          </Card>
        </Card>
      ))}

      <div
        className="offcanvas-footer  p-3 text-end position-fixed  end-0"
        style={{ bottom: "10%" }}
      >
        <Tooltips msg={"Download Excel"} direction={"left"}>
          <Button
            disabled={isDownloading || isLeadDataLoading}
            onClick={downloadData}
          >
            {(isDownloading || isLeadDataLoading) && <Spinner size="sm" />}
            <i className="bi bi-file-earmark-excel-fill"></i>
          </Button>
        </Tooltips>
      </div>
    </div>
  );
};

export const UserDrawerSkeleton = ({ name }) => {
  return (
    <>
      <div>
        <div aria-hidden="true" className="card">
          <div className="card-body">
            <div className="border border-gray p-2 m-2">
              {new Array(6).fill(0).map((item, index) => {
                return (
                  <React.Fragment key={index + name}>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                    </p>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
