import { toast } from "react-toastify";
import { centerCrop, makeAspectCrop } from "react-image-crop";
import { useEffect } from "react";
import { formatDateCustomerFilter } from "components/common/CollumnFormator";
import { saveAs } from "file-saver";
import { AES, enc } from "crypto-js";
import { kinks } from "@turf/kinks";
import {polygon } from "@turf/helpers"
import {booleanValid} from "@turf/boolean-valid"
export const showSuccessMsg = (msg = "") => {
  toast.success(msg);
};

export const showErrorMsg = (msg = "Something went wrong.") => {
  toast.error(msg);
};
export const showWarningMsg = (msg = "Invalid") => {
  toast.warning(msg);
};

export const isValidArray = (data) => {
  return data && Array.isArray(data) && data.length > 0;
};

export const isValidObject = (data) => {
  return (
    typeof data === "object" && data !== null && Object.keys(data).length !== 0
  );
};
// const getChartColorsArray = (colors) => {
//   colors = JSON?.parse(colors);
//   return colors.map(function (value) {
//     var newValue = value.replace(" ", "");
//     if (newValue.indexOf(",") === -1) {
//       var color = global
//         .getComputedStyle(document.documentElement)
//         .getPropertyValue(newValue);

//       if (color.indexOf("#") !== -1) color = color.replace(" ", "");
//       if (color) return color;
//       else return newValue;
//     } else {
//       var val = value.split(",");
//       if (val.length === 2) {
//         var rgbaColor = global
//           .getComputedStyle(document.documentElement)
//           .getPropertyValue(val[0]);
//         rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
//         return rgbaColor;
//       } else {
//         return newValue;
//       }
//     }
//   });
// };
export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

const TO_RADIANS = Math.PI / 180;

const canvasPreview = (image, canvas, crop, scale = 1, rotate = 0) => {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
};

const useDebounceEffect = (fn, waitTime, deps) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line
  }, deps);
};

// which is a bit trickier so we use some helper functions.
const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};
const MAX_IMAGE_SIZE = 5; // 5MB
const MAX_PROPERTY_IMAGE_SIZE = 10; // 10MB

export const createFirstLetterCapital = (value) => {
  return value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : "";
};

export const firstLetterUpperCase = (str) => {
  if (!str) {
    return str;
  }
  return str?.[0].toUpperCase() + str?.substring(1);
};

export const createAllFirstLetterCapital = (value = "") => {
  if (!!value) {
    // Split the input string by hyphen, underscore, or space
    const words = value.split(/[-_ ]+/);

    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );

    const formattedString = formattedWords.join(" ");
    return formattedString;
  }
  return "";
};

export const setBodyForCustomerApi = (mapFilters) => {
  let data = {};
  if (mapFilters?.property?.value) {
    data = { ...data, property: mapFilters?.property?.value };
  }
  if (mapFilters?.type?.value) {
    data = { ...data, type: mapFilters?.type?.value };
  }
  if (mapFilters?.structure?.value) {
    data = { ...data, structure: mapFilters?.structure?.value };
  }
  if (mapFilters?.redeck?.value) {
    data = { ...data, redeck: mapFilters?.redeck?.value };
  }
  if (mapFilters?.group?.value) {
    data = { ...data, groups: mapFilters?.group?.value };
  }
  if (mapFilters?.material?.value) {
    data = { ...data, material: mapFilters?.material?.value };
  }
  if (mapFilters?.location) {
    data = { ...data, location: { ...mapFilters?.location } };
  }
  if (mapFilters["start date"] !== "" && mapFilters["end date"] !== "") {
    let startDate = formatDateCustomerFilter(mapFilters["start date"]);
    let endDate = formatDateCustomerFilter(mapFilters["end date"]);
    data = {
      ...data,
      startDate,
      endDate,
    };
  }
  return data;
};

export const convertToExcel = (data) => {
  const bData = new Uint8Array(data?.buffer?.data);
  const blob = new Blob([bData], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, data?.name);
};

export const timeFormat = (messageDate) => {
  let date = new Date(messageDate);
  let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    date?.toLocaleDateString("en-GB", {
      timeZone: zone,
      year: "2-digit",
      day: "2-digit",
      month: "short",
    }) +","+
    "  " +
    date
      ?.toLocaleTimeString("en-GB", {
        timeZone: zone,
        hour: "2-digit",
        hour12: true,
        minute: "2-digit",
      })
      .toUpperCase()
  );
};
export const containerStyle = {
  width: "100%",
  height: "inherit",
};

export const autocompleteStyle = {
  boxSizing: "border-box",
  border: "1px solid transparent",
  width: "240px",
  height: "38px",
  padding: "0 12px",
  borderRadius: "3px",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
  fontSize: "14px",
  outline: "none",
  textOverflow: "ellipses",
  position: "absolute",
  right: "8%",
  top: "11px",
  marginLeft: "-120px",
  zIndex: 20,
};

export const polygonOptions = {
  fillOpacity: 0.3,
  fillColor: "#FFFFFF",
  strokeColor: "#ff0000",
  strokeWeight: 2,
};

export const encryption = (rawData) => {
  let data = JSON.stringify(rawData);
  const cipherText = AES.encrypt(data, "enckey");
  return cipherText.toString();
};
export const decryption = (rawData) => {
  if (!!rawData) {
    let bytes = AES.decrypt(rawData, "enckey");
    const decrypted = bytes.toString(enc.Utf8);
    if (!!decrypted) {
      return JSON.parse(decrypted);
    }
  } else {
    return "";
  }
};
export const getArrayDepth = (array) => {
  if (Array.isArray(array)) {
    return 1 + Math.max(0, ...array.map(getArrayDepth));
  } else {
    return 0;
  }
};
export const validatePolygon = (coordinates) => {
  // Convert coordinates to Turf.js polygon format
  const polygons = polygon([coordinates]);
  // Check if the polygon is valid (simple, closed, etc.)
  const isValid = booleanValid(polygons); // Simple validity check
  if (isValid && !kinks(polygons)?.features.length > 0) {
    return true;
  } else {
    return false;
  }
};
export {
  centerAspectCrop,
  useDebounceEffect,
  canvasPreview,
  MAX_IMAGE_SIZE,
  MAX_PROPERTY_IMAGE_SIZE,
};
