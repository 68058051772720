import * as yup from "yup";
// eslint-disable-next-line
const passwordFormat = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
const passwordFormatMsg =
  "Password must contain at least 1 letter and 1 number";
const phoneFormat = /^(|(\+?1-?)) ?((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
const phoneFormatMsg =
  "Phone number format is invalid. It should be formatted like +1 (xxx) xxx-xxxx.";

const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const emailFormatMsg = "Invalid Email";


export const loginFormValidation = () => {
  return yup.object({
    email: yup
      .string()
      .email("Please Enter Valid Email")
      .matches(emailFormat, emailFormatMsg)
      .required("Please Enter Your Email"),
    password: yup.string().required("Please Enter Your Password"),
  });
};

export const registerFormValidation = () => {
  return yup.object({
    email: yup
      .string()
      .matches(emailFormat, emailFormatMsg)
      .required("Please Enter Email"),
    username: yup.string().required("Please Enter Username"),
    password: yup
      .string()
      .required("Please Enter Password")
      .matches(passwordFormat, passwordFormatMsg),
    confirmPassword: yup
      .string()
      .required("Confirm password required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    acceptTerms: yup.bool().oneOf([true], "Accept the terms and conditions"),
  });
};

export const resetPasswordFormValidation = () => {
  return yup.object({
    password: yup
      .string()
      .required("Please Enter Password")
      .matches(passwordFormat, passwordFormatMsg),
    confirmPassword: yup
      .string()
      .required("Confirm password required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
};

export const updateAdminProfileValidation = () => {
  return yup.object({
    name: yup.string().required("Please Enter Your Name"),
  });
};

export const changePasswordValidation = () => {
  return yup.object({
    oldPassword: yup.string().required("Please Enter Your Old Password"),
    newPassword: yup
      .string()
      .required("Please Enter Your New Password")
      .min(8, "Password should be greater than or equal to 8 characters.")
      .max(50, "Password should be less than or equal to 50 characters.")
      .matches(passwordFormat, passwordFormatMsg)
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d).+$/,
        "Password must contain at least 1 letter and 1 number",
      ),
    confirmPassword: yup
      .string()
      .required("Confirm Password Required")
      .min(8, "Password should be greater than or equal to 8 characters.")
      .max(50, "Password should be less than or equal to 50 characters.")
      .oneOf([yup.ref("newPassword"), null], "Passwords Must Match"),
  });
};

export const userChangePasswordValidation = () => {
  return yup.object({
    newPassword: yup
      .string()
      .required("Please Enter Your New Password")
      .matches(passwordFormat, passwordFormatMsg),
    confirmPassword: yup
      .string()
      .required("Confirm Password Required")
      .oneOf([yup.ref("newPassword"), null], "Passwords Must Match"),
  });
};

export const advanceFormValidation = () => {
  return yup.object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    fullName: yup.string().required("Full name is required."),
    birthDate: yup.string().required("Birth date is required."),
    phone: yup
      .string()
      .test("phone-validation", phoneFormatMsg, function (value) {
        if (!value || value === "1" || value === "+1 (   )    -    ") {
          return this.createError({
            message: "Phone is required.",
          });
        } else {
          return yup
            .string()
            .matches(phoneFormat, phoneFormatMsg)
            .isValidSync(value);
        }
      }),
    email: yup
      .string()
      .matches(emailFormat, emailFormatMsg)
      .required("Email is required."),
    state: yup.string().required("State is required."),
    city: yup.string().required("City is required."),
    address: yup.string().required("Address is required."),
    zip: yup.string().required("Zip is required."),
    gender: yup.string().required("Gender is required."),
    amount: yup.string().required("Amount is required."),
    hobbies: yup.string().required("Hobbies is required."),
    languages: yup.string().required("Languages is required."),
    dynamicField: yup.array().of(
      yup.object({
        field_name: yup
          .string()
          .trim()
          .matches(/\S+/, "Offer name can't be blank.")
          .required("Offer name is required."),
      }),
    ),
  });
};
export { emailFormat, passwordFormat, passwordFormatMsg };
